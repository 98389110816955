<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <g id="Group_28">
      <circle
        id="Ellipse_23"
        cx="6.45"
        cy="4.15"
        r="3.27"
        style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
      />
      <path
        id="Path_454"
        d="m1.55,13.95v-1.63c0-1.8,1.46-3.27,3.27-3.27h3.27c1.8,0,3.27,1.46,3.27,3.27v1.63"
        style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
      />
    </g>
  </svg>
</template>

<style scoped></style>
